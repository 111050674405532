<template>
    <div>
        <a-sidebar></a-sidebar>
        <div class="main-content" id="panel">
            <a-mobile-sidebar></a-mobile-sidebar>
            <a-header title="Branches" class="bg-purple text-dark">
                <div class="col-md-12">
                    <stats-card card-title="Branch Information" card-sub-title="Number of Branches">
                    </stats-card>
                </div>
            </a-header>
            <a-content>
                <div class="col-md-8">
                    <table-card card-title="Created Branches">
                        <table class="table align-items-center table-flush" v-if="records">
                            <thead class="thead-light">
                            <tr>
                                <th scope="col">Branch Name</th>
                                <th scope="col">Location</th>
                                <th scope="col">Status</th>
                                <th scope="col"><i class="ni ni-ui-04"></i></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="record in records" :key="record.branch_id">
                                <td>{{ record.name }}</td>
                                <td>{{ record.location }}</td>
                                <td>{{ record.status }}</td>
                                <td><button @click="openUpdateForm(record)" class="btn btn-secondary btn-sm"><i class="fas fa-edit"></i></button></td>
                            </tr>
                            </tbody>
                        </table>
                        <div v-else class="alert alert-secondary">
                            No Records Yet!
                        </div>
                    </table-card>
                </div>
                <div class="col-md-4">
                    <form-card card-title="Edit Record Form" card-sub-title="Update Branch" v-if="showUpdateForm">
                        <form v-on:submit.prevent="updateForm">
                            <div class="form-group mb-3">
                                <div class="input-group input-group-merge input-group-alternative">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="ni ni-shop"></i></span>
                                    </div>
                                    <input class="form-control" placeholder="Name" type="text" v-model="form.name">
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group input-group-merge input-group-alternative">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="ni ni-world-2"></i></span>
                                    </div>
                                    <input class="form-control" placeholder="Location" type="text" v-model="form.location">
                                </div>
                            </div>
                            <div class="text-center">
                                <button type="submit" class="btn btn-warning my-4"><i class="ni ni-check-bold"></i> Update</button> &nbsp; 
                                <button @click="closeUpdateForm" class="btn btn-default my-4"><i class="ni ni-fat-remove"></i> Close</button>
                            </div>
                        </form>
                    </form-card>
                    <form-card card-title="New Form" card-sub-title="Create New Branch" v-else>
                        <form v-on:submit.prevent="createForm">
                            <div class="form-group mb-3">
                                <div class="input-group input-group-merge input-group-alternative">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="ni ni-shop"></i></span>
                                    </div>
                                    <input class="form-control" placeholder="Name" type="text" v-model="form.name">
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-group input-group-merge input-group-alternative">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="ni ni-world-2"></i></span>
                                    </div>
                                    <input class="form-control" placeholder="Location" type="text" v-model="form.location">
                                </div>
                            </div>
                            <div class="text-center">
                                <button :disabled="isDisabled" type="submit" class="btn btn-primary my-4"><i class="ni ni-check-bold"></i> Create</button>
                            </div>
                        </form>
                    </form-card>
                </div>
            </a-content>
            <a-footer></a-footer>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import AdminContentVue from '../../components/Admin/AdminContent.vue'
import AdminFooterVue from '../../components/Admin/AdminFooter.vue'
import AdminHeaderVue from '../../components/Admin/AdminHeader.vue'
import NoButtonWhiteCardVue from '../../components/Cards/NoButtonWhiteCard.vue'
import StatsCardVue from '../../components/Cards/StatsCard.vue'
import TableOneButtonCardVue from '../../components/Cards/TableOneButtonCard.vue'
import AdminMobileNavVue from '../../components/Nav/AdminMobileNav.vue'
import AdminSideBarVue from '../../components/Nav/AdminSideBar.vue'
export default {
    name: 'AdminBranch',
    components: {
        'a-header': AdminHeaderVue,
        'a-mobile-sidebar': AdminMobileNavVue,
        'a-sidebar': AdminSideBarVue,
        'a-content': AdminContentVue,
        'a-footer': AdminFooterVue,
        'stats-card': StatsCardVue,
        'table-card': TableOneButtonCardVue,
        'form-card': NoButtonWhiteCardVue,
    },
    data() {
        return{
            records: null,
            isDisabled:false,
            showUpdateForm: false,
            form:{
                name: null,
                location: null,
            }
        }
    },
    mounted(){
        this.getRecords()
        
    },
    methods:{
        createForm(){
            this.isDisabled = true
            axios.post(process.env.VUE_APP_BASEURL+'/admin/branches', this.form,{ headers: 
                    {
                        'Authorization': 'Bearer '+this.authToken 
                    }
                })
                 .then((response) => {
                    //Perform Success Action
                    if (response.status == '200') {
                        this.$moshaToast(response.data.message,{
                            type: 'success'
                        });
                        this.getRecords();
                    }
                 })
                 .catch((error) => {
                    // error.response.status Check status code
                    if (error.response.status === 422) {
                        this.$moshaToast(JSON.stringify(error.response.data.errors),{
                            type: 'danger'
                
                        });                        
                    }else{
                        this.$moshaToast(error.response.data.message,{
                            type: 'danger'
                        });
                    }
                    this.isDisabled = false
                 })
                 .finally(() => {
                    //Perform other dependent actions
                    this.isDisabled = false
                 });
        },

        updateForm(){
            axios.put(process.env.VUE_APP_BASEURL+'/admin/branches/'+this.recordId, this.form,{ 
                headers: 
                    {
                        'Authorization': 'Bearer '+this.authToken 
                    }
                })
                 .then((response) => {
                    //Perform Success Action
                    if (response.status == '200') {
                        this.$moshaToast(response.data.message,{
                            type: 'success'
                        });
                        this.getRecords();
                    }
                 })
                 .catch((error) => {
                    // error.response.status Check status code
                    if (error.response.status === 422) {
                        this.$moshaToast(JSON.stringify(error.response.data.errors),{
                            type: 'danger'
                
                        });                        
                    }else{
                        this.$moshaToast(error.response.data.message,{
                            type: 'danger'
                        });
                    }
                    this.isDisabled = false
                 })
        },

        getRecords(){
            axios.get(process.env.VUE_APP_BASEURL+'/admin/branches',{
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken 
                }
            })
            .then(response => {
                this.records = response.data.data
                localStorage.defaultbranch = JSON.stringify( response.data.data[0])
            })
            .catch(error => {
                this.$moshaToast(error.response.data.message,{
                    type: 'danger'
                });
                if (error.response.status === 401) {
                    localStorage.removeItem('mytoken');
                    this.$router.push('/login');
                }
                else if (error.response.status === 403) {
                    this.$router.push('/login');
                }
            })
        },

        openUpdateForm(record){
            this.showUpdateForm = true,
            this.recordId = record.branch_id,
            this.form.name = record.name,
            this.form.location = record.location
        },
        closeUpdateForm(){
            this.showUpdateForm = false,
            this.form.name = null,
            this.form.location = null
        }

    },
}
</script>